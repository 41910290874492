<template>
  <div
    v-if="$route.name !== 'pricingPage'"
    class="footer-block"
  >
    <div class="container">
      <div class="footer-block-content">
        <div class="footer-block-left">
          <div class="logo">
            <img
              src="../assets/images/logo.png"
              alt=""
            >
          </div>
        </div>
        <div class="footer-block-center">
          <!--          <div class="footer-block__pre">-->
          <a
            v-if="$route.name !== 'pricingStep2'"
            href="https://www.impactfactors.net/affiliates"
            target="_blank"
            class="btn footer-block__btn"
          >
            Join Our Affiliate Program
          </a>
          <!--          </div>-->
          <div
            class="footer-block-center-content"
            :class="{'footer-block-center-content_top': $route.name === 'pricingStep2'}"
          >
            <span class="footer-block__rights">
              Impact Factors, LLC &copy; <span>{{ getYear() }}</span>. All Rights Reserved.
            </span>
            <a
              class="footer-block__privacy"
              @click.prevent="showModalPrivacyPolicy = true"
            >
              Privacy Policy
            </a>
          </div>
        </div>
        <div class="footer-block-right">
          <div class="footer-block-links">
            <a
              v-tooltip.top="'Contact Us'"
              href="mailto:sales@impactfactors.net"
              class="footer-block-links__item"
            >
              <i class="icon-mail"></i>
            </a>
            <a
              v-tooltip.top="'Support'"
              href="mailto:support@impactfactors.net"
              class="footer-block-links__item"
            >
              <i class="icon-chat"></i>
            </a>
            <a
              v-tooltip.top="'Ideas'"
              href="mailto:ideas@impactfactors.net"
              class="footer-block-links__item"
            >
              <i class="icon-idea"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <transition
      name="fade-block-wait"
      appear
      mode="out-in"
    >
      <modals-privacy-policy
        v-if="showModalPrivacyPolicy"
      >
      </modals-privacy-policy>
    </transition>
  </div>
</template>

<script>
const modalsPrivacyPolicy = () => import('@/components/modals/modalsPrivacyPolicy');

export default {
  name: 'FooterBlockBlock',
  components: {
    modalsPrivacyPolicy,
  },
  data() {
    return {
    };
  },
  computed: {
    showModalPrivacyPolicy: {
      get() {
        return this.$store.getters.showModalPrivacyPolicy;
      },
      set(data) {
        this.$store.commit('setShowModalPrivacyPolicy', data);
      },
    },
  },
  methods: {
    getYear() {
      const today = new Date();
      const year = today.getFullYear();
      return year;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '../assets/sass/utils/variables';
  .footer-block {
    display: flex;
    min-height: 234px;
    align-items: center;
    background: $color-shark;
    padding-top: 50px;
    position: relative;

    /*&_pricing {*/
      /*position: fixed;*/
      /*width: 100%;*/
      /*z-index: 99999;*/
      /*top: auto;*/
      /*bottom: 0;*/
    /*}*/

    .logo {
      max-width: 255px;
    }

    &-links{
      position: relative;
      top: 8px;

      &__item {
        color: $color-moody-blue;
        margin: 0 6px;
        transition: opacity .3s ease;

        &:hover, :focus {
          opacity: .6;
        }

        .icon {
          position: relative;

          &-mail {
            font-size: 22px;
            position: relative;
          }

          &-chat {
            font-size: 28px;
            position: relative;
            top: 2px;
          }

          &-idea {
            font-size: 28px;
            position: relative;
            top: 2px;
          }
        }

      }
    }

    &__pre {
      height: 12px;
    }

    &__btn {
      background: $color-moody-blue;
      height: 40px;
      border: none;
      font-family: $font-global-medium, sans-serif;
      font-size: 16px;
      color: $color-white;
      padding: 0 6px;
      width: 234px;
      border-radius: 5px;
      margin-bottom: 40px;
      text-decoration: none;
      transition: opacity .2s ease-in, background .2s ease-in;
      user-select: none;

      &:hover {
        opacity: .8;
      }

      &:active {
        background: $color-martinique;
        opacity: 1;
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        width: 10px;
        height: 10px;
        background: rgba(255, 255, 255, .5);
        opacity: 0;
        border-radius: 100%;
        transform: scale(1, 1) translate(-50%);
        transform-origin: 50% 50%;
      }

      @keyframes ripple {
        0% {
          transform: scale(0, 0);
          opacity: 1;
        }
        20% {
          transform: scale(25, 25);
          opacity: 1;
        }
        100% {
          opacity: 0;
          transform: scale(40, 40);
        }
      }

      &:focus:not(:active)::after {
        animation: ripple 1.5s ease-out;
      }
    }

    &__rights {
      line-height: 18px;
      font-size: 14px;
      color: $color-jumbo;
    }

    &__privacy {
      color: $color-white;
      line-height: 18px;
      font-size: 14px;
      text-decoration: none;
      margin-left: 8px;
      transition: opacity .3s ease;
      cursor: pointer;

      &:hover, &:focus {
        opacity: .6;
      }
    }

    &-content {
      display: flex;
      margin: 0 -15px;
    }

    &-left {
      width: 25%;
    }

    &-center {
      width: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      top: 5px;

      &-content {
        display: flex;

        &_top {
          margin-top: 12px;
        }
      }
    }

    &-right {
      width: 25%;
      justify-content: flex-end;
      position: relative;
      display: flex;
    }
  }

  @media (max-width: 1199px) {
    .footer-block {
      padding-bottom: 20px;
      min-height: auto!important;

      &__rights {
        text-align: center;
      }

      &-right {
        width: 100%;
        justify-content: center;
      }

      &-left {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
      }

     &-content {
       flex-direction: column;
       align-items: center;
     }

     &-center {
       width: 100%;

       &-content {
         flex-direction: column;
         align-items: center;
         margin-bottom: 20px;
       }
     }
    }
  }

  @media (max-width: 991px) {
    .footer-block {
      &-center-content {
        flex-direction: column;
        align-items: center;
      }
    }
  }

  @media (max-width: 480px) {
    .footer-block {
      min-height: 100px;
      padding-top: 20px;
      padding-bottom: 100px;

      &__privacy {
        margin-left: 0;
      }

      &-content {
        flex-direction: column;
        margin: 0;
      }

      &-left {
        display: none;
      }

      &-center {
        width: 100%;

        &-content {
          position: absolute;
          bottom: -105px;
          text-align: center;
        }
      }

      &__btn {
        width: 187px;
        height: 32px;
        font-size: 14px;
      }

      &-right {
        width: 100%;
        justify-content: center;
        position: relative;
        top: -14px;
      }
    }
  }
</style>

<style lang="scss">
  @import '../assets/sass/utils/variables';

  .tooltip {
    display: block !important;
    z-index: 10000;

    .tooltip-inner {
      //background-color: $color-abbey;
      background-color: $color-jumbo;
      border-radius: 3px;
      font: 12px/1.2 $font-global;
      color: $color-white;
      padding: 7px 15px;
      box-shadow: none;
      max-width: 270px;
      pointer-events: none;
    }

    .tooltip-arrow {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      //border-color: $color-abbey;
      border-color: $color-jumbo;
    }
  }

  .tooltip[x-placement^="top"] {
    margin-bottom: 5px;
  }

  .tooltip[x-placement^="top"] .tooltip-arrow {
    border-width: 5px 5px 0 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    bottom: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
  }

  .tooltip[x-placement^="bottom"] {
    margin-top: 5px;
  }

  .tooltip[x-placement^="bottom"] .tooltip-arrow {
    border-width: 0 5px 5px 5px;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
    top: -5px;
    left: calc(50% - 5px);
    margin-top: 0;
    margin-bottom: 0;
  }

  .tooltip[x-placement^="right"] {
    margin-left: 5px;
  }

  .tooltip[x-placement^="right"] .tooltip-arrow {
    border-width: 5px 5px 5px 0;
    border-left-color: transparent !important;
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
    left: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
  }

  .tooltip[x-placement^="left"] {
    margin-right: 5px;
  }

  .tooltip[x-placement^="left"] .tooltip-arrow {
    border-width: 5px 0 5px 5px;
    border-top-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: transparent !important;
    right: -5px;
    top: calc(50% - 5px);
    margin-left: 0;
    margin-right: 0;
  }

  .tooltip[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  .tooltip[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }

</style>
